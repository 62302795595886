import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  OverlayView,
  Polyline,
} from "@react-google-maps/api";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { DeliveryPeopleService } from "../../service/DeliveryPeopleService";
import io from "socket.io-client";
import { DateTime } from "luxon";
import { decode, encode } from "@googlemaps/polyline-codec";

const prod_url = "https://api.cpo-app.com";
const dev = "ws://localhost:3000";
const socket = io(prod_url, { autoConnect: false });

const containerStyle = {
  width: "90vw",
  height: "450px",
};

const center = {
  lat: 34.008325729257756,
  lng: -6.853770974342919,
};
const mapStyles = [
  {
    featureType: "poi",
    elementType: "all",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [{ visibility: "on" }, { saturation: 50 }, { lightness: -10 }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#008000" }, { weight: 1.5 }],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      { visibility: "on" },
      { color: "#ff0000" }, // Distinct icon color
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [{ color: "#f2f2f2" }],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [{ saturation: -100 }, { lightness: 45 }],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [{ color: "#b7e4f4" }, { visibility: "on" }],
  },
];

const customersCoordinates = [
  {
    customerName: "PHARMACIE GALIEN (SHN) ©️",
    coordinates: { lat: 33.97265845140887, lng: -6.824728301087408 },
  },
  {
    customerName: "UNIVERS PARA DISCOUNT MOUNAW (SHN) ©️",
    coordinates: { lat: 33.95982391533871, lng: -6.871541149415834 },
  },
  {
    customerName: "PHARMACIE AL MINBAT (SHN)©️",
    coordinates: { lat: 33.978539455549715, lng: -6.8368464724713 },
  },
  {
    customerName: "PHARMACIE EL WAHDA RABAT (SHN) ©️",
    coordinates: { lat: 33.98691496241014, lng: -6.801242501626136 },
  },
  {
    customerName: "PHARMACIE LES LILAS RABAT (SHN) ©️",
    coordinates: { lat: 33.97168284290005, lng: -6.83590713721467 },
  },
  {
    customerName: "PHARMACIE AL WAHA RABAT (SHN) ©️",
    coordinates: { lat: 33.97567965488585, lng: -6.8222301013618845 },
  },
];

const RealTimeTracking = ({ deliveryPeople }) => {
  const deliveryPeopleService = new DeliveryPeopleService();
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [deliveryMenPositions, setDeliveryMenPositions] = useState([]);
  const [selectedDeliveryMan, setSelectedDeliveryMan] = useState(null);
  const [loadingLastPosition, setLoadingLastPosition] = useState(true);
  const hideDialog = () => setDialogVisibility(false);
  const openNew = () => setDialogVisibility(true);
  const [map, setMap] = React.useState(null);
  const toast = React.useRef(null);
  const [zoom, setZoom] = useState(15);
  const [center, setCenter] = useState({
    lat: 34.008325729257756,
    lng: -6.853770974342919,
  });
  const [polylineDeliveryMan, setPolylineDeliveryMan] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAOSDEF3tpixNJIbh5IpDPUaZXiuJJQGqg",
  });

  useMemo(() => {
    if (dialogVisibility) {
      let data = [];
      deliveryPeople.forEach((deliveryMan) => {
        data.push({
          _id: deliveryMan._id,
          fullName: deliveryMan.fullName,
          lastPositionDate: null,
          lastPosition: {
            latitude: null,
            longitude: null,
            angle: null,
          },
          location: {
            latitude: null,
            longitude: null,
            angle: null,
            speed: null,
          },
          customerToDeliver: null,
          deliverySessionDetails: null,
        });
      });
      setDeliveryMenPositions([...data]);
    }
  }, [dialogVisibility]);

  useEffect(() => {
    if (selectedDeliveryMan) {
      const deliveryManPosition = deliveryMenPositions.find(
        (deliveryManPos) => deliveryManPos._id === selectedDeliveryMan
      );
      if (deliveryManPosition) {
        if (deliveryManPosition.deliverySessionDetails) {
          console.log(deliveryManPosition.deliverySessionDetails);
          const encodedPolyline =
            deliveryManPosition.deliverySessionDetails.overviewPolyline;
          const decodedPolyline = decode(encodedPolyline).map(
            ([latitude, longitude]) => ({
              lat: latitude,
              lng: longitude,
            })
          );
          setPolylineDeliveryMan(decodedPolyline);
        } else {
          setPolylineDeliveryMan(null);
        }
      }
    } else {
    }
  }, [selectedDeliveryMan, deliveryMenPositions]);

  console.log(polylineDeliveryMan);

  useEffect(() => {
    if (dialogVisibility) {
      getLastPositionsDeliveryMen();

      socket.connect();
      socket.on("connect", () => {
        console.log("Socket connected:", socket.connected);
        socket.on("location_updated", (data) => {
          console.log("date recevied via socket");
          console.log(data);
          const indexDeliveryMan = deliveryMenPositions.findIndex(
            (deliveryManPos) => deliveryManPos._id === data.userId
          );
          if (indexDeliveryMan !== -1) {
            console.log("deliveryman founded: ", indexDeliveryMan);
            const _deliveryMenPositions = [...deliveryMenPositions];
            _deliveryMenPositions[indexDeliveryMan].location.latitude =
              data.location.latitude;
            _deliveryMenPositions[indexDeliveryMan].location.longitude =
              data.location.longitude;
            _deliveryMenPositions[indexDeliveryMan].location.angle =
              data.location.heading;
            _deliveryMenPositions[indexDeliveryMan].location.speed =
              data.location.speed;
            _deliveryMenPositions[indexDeliveryMan].customerToDeliver =
              data.customerName ? data.customerName : null;
            _deliveryMenPositions[indexDeliveryMan].lastPositionDate =
              DateTime.now().setZone("Africa/Casablanca");
            _deliveryMenPositions[indexDeliveryMan].deliverySessionDetails =
              data.deliverySessionDetails;
            setDeliveryMenPositions([..._deliveryMenPositions]);
          } else {
            console.log("Delivery man not found");
          }
        });
      });
      socket.on("connect_error", (error) => {
        console.error("Connection error:", error);
      });
    }
    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });
    // Clean up to avoid multiple listeners
    return () => {
      socket.off(`location_updated`);
      socket.disconnect();
      setLoadingLastPosition(true);
    };
  }, [dialogVisibility]);

  useEffect(() => {
    if (!loadingLastPosition) {
      if (
        deliveryMenPositions.find((deliveryManPos) => {
          return (
            deliveryManPos.location.latitude ||
            deliveryManPos.lastPosition.latitude
          );
        })
      ) {
        fitBounds();
      }
    }
  }, [dialogVisibility, loadingLastPosition]);

  const getLastPositionsDeliveryMen = async () => {
    let newData = [...deliveryMenPositions];
    for (let deliveryMan of deliveryPeople) {
      const response = await deliveryPeopleService.getLastPosition(
        deliveryMan._id
      );
      if (!response.error) {
        if (response.data) {
          const index = deliveryMenPositions.findIndex(
            (deliveryManPos) => deliveryManPos._id === deliveryMan._id
          );
          if (index !== -1) {
            newData[index].lastPosition.latitude =
              response.data.coordinates.latitude;
            newData[index].lastPosition.longitude =
              response.data.coordinates.longitude;
            newData[index].lastPosition.angle = response.data.coordinates.angle;
            newData[index].lastPositionDate = DateTime.fromISO(
              response.data.createdAt
            ).setZone("Africa/Casablanca");
          }
        }
      } else {
        console.log(response.error);
      }
    }
    setDeliveryMenPositions([...newData]);
    setLoadingLastPosition(false);
  };

  const onMapLoad = useCallback((map) => {
    map.addListener("dragend", () => {
      const newCenter = map.getCenter();
      setCenter({
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      });
    });

    map.addListener("zoom_changed", () => {
      setZoom(map.getZoom());
    });
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMouseOver = (markerId) => {
    setHoveredMarker(markerId);
  };

  const handleMouseOut = () => {
    setHoveredMarker(null);
  };

  // const animateMarker = (indexDeliveryMan, newPosition) => {
  //   const start = {
  //     lat: deliveryMenPositions[indexDeliveryMan].location.latitude,
  //     lng: deliveryMenPositions[indexDeliveryMan].location.longitude,
  //   };
  //   const duration = 4000; // Duration of the animation in ms
  //   let startTime;

  //   const animateStep = (timestamp) => {
  //     if (!startTime) startTime = timestamp;
  //     const progress = (timestamp - startTime) / duration;

  //     Interpolate latitude and longitude for smooth transition
  //     const lat = start.lat + (newPosition.lat - start.lat) * progress;
  //     const lng = start.lng + (newPosition.lng - start.lng) * progress;
  //     const newData = [...deliveryMenPositions]
  //     newData[indexDeliveryMan].location.latitude = lat
  //     newData[indexDeliveryMan].location.longitude = lng
  //     setDeliveryMenPositions([...newData]);

  //     if (progress < 1) {
  //       requestAnimationFrame(animateStep); // Continue animation
  //     } else {
  //       const newData = [...deliveryMenPositions]
  //       newData[indexDeliveryMan].location.latitude = newPosition.lat
  //       newData[indexDeliveryMan].location.longitude = newPosition.lng
  //       newData[indexDeliveryMan].lastPositionDate = DateTime.now().setZone("Africa/Casablanca")
  //       setDeliveryMenPositions([...newData]);
  //     }
  //   };

  //   requestAnimationFrame(animateStep);
  // };

  const generateHeaderText = () => {
    if (loadingLastPosition) {
      return <p>chargement des emplacements des livreurs...</p>;
    }
    let hastLastDate = false;
    let hasCustomer = false;
    if (selectedDeliveryMan) {
      const index = deliveryMenPositions.findIndex(
        (deliveryManPos) => deliveryManPos._id === selectedDeliveryMan
      );
      if (index !== -1) {
        if (deliveryMenPositions[index].lastPositionDate) hastLastDate = true;
        if (deliveryMenPositions[index].customerToDeliver) {
          hasCustomer = true;
        }
      }
      return (
        <p>
          {hastLastDate && (
            <p>
              Date dernière position:{" "}
              {deliveryMenPositions[index].lastPositionDate.toFormat(
                "yyyy-MM-dd HH:mm"
              )}
            </p>
          )}
          {hasCustomer && (
            <p>
              En route vers le client
              <b>{deliveryMenPositions[index].customerToDeliver}</b>
            </p>
          )}
        </p>
      );
    }
    return <></>;
  };

  const renderMarkersCustomers = () => {
    const deliveryManPos = deliveryMenPositions.find((deliveryManPos) => {
      return deliveryManPos._id === selectedDeliveryMan;
    });
    if (deliveryManPos && deliveryManPos.deliverySessionDetails) {
      return deliveryManPos.deliverySessionDetails.customersCoordinates.map(
        (customerCoordinate, index) => {
          return (
            <>
              <Marker
                key={customerCoordinate.customerName}
                label={{
                  text: (index + 1).toString(),
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
                position={{
                  lat: customerCoordinate.coordinates.lat,
                  lng: customerCoordinate.coordinates.lng,
                }}
                onMouseOver={() =>
                  handleMouseOver(customerCoordinate.customerName)
                }
                onMouseOut={handleMouseOut}
              />
              {hoveredMarker &&
                hoveredMarker === customerCoordinate.customerName && (
                  <OverlayView
                    position={{
                      lat: customerCoordinate.coordinates.lat,
                      lng: customerCoordinate.coordinates.lng,
                    }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "40px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        background: "white",
                        border: "1px solid #ccc",
                        padding: "5px",
                        borderRadius: "5px",
                        fontSize: "10px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
                      }}
                    >
                      {customerCoordinate.customerName}
                    </div>
                  </OverlayView>
                )}
            </>
          );
        }
      );
    }
  };

  const onSelectedDeliveryManChange = (e) => {
    setSelectedDeliveryMan(e.value);
    if (e.value) {
      const index = deliveryMenPositions.findIndex(
        (deliveryManPos) => deliveryManPos._id === e.value
      );
      if (index !== -1) {
        if (
          deliveryMenPositions[index].location.latitude ||
          deliveryMenPositions[index].lastPosition.latitude
        ) {
          setCenter({
            lat:
              deliveryMenPositions[index].location.latitude ||
              deliveryMenPositions[index].lastPosition.latitude,
            lng:
              deliveryMenPositions[index].location.longitude ||
              deliveryMenPositions[index].lastPosition.longitude,
          });
          setZoom(15);
        }
      }
    } else {
      if (
        deliveryMenPositions.find((deliveryManPos) => {
          return (
            deliveryManPos.location.latitude ||
            deliveryManPos.lastPosition.latitude
          );
        })
      ) {
        fitBounds();
      }
    }
  };

  const fitBounds = () => {
    console.log("fit bounds");
    const bounds = new window.google.maps.LatLngBounds();
    deliveryMenPositions.forEach((deliveryManPos) => {
      if (
        deliveryManPos.location.latitude ||
        deliveryManPos.lastPosition.latitude
      )
        bounds.extend({
          lat:
            deliveryManPos.location.latitude ||
            deliveryManPos.lastPosition.latitude,
          lng:
            deliveryManPos.location.longitude ||
            deliveryManPos.lastPosition.longitude,
        });
    });
    map.fitBounds(bounds);
  };

  const header = () => {
    return (
      <div style={{ display: "flex" }}>
        <Dropdown
          filter
          value={selectedDeliveryMan}
          placeholder="Livreurs"
          options={deliveryPeople}
          optionLabel="fullName"
          optionValue="_id"
          showClear
          onChange={onSelectedDeliveryManChange}
          className="p-column-filter p-inputtext-sm"
          style={{ width: "30%", marginRight: 10 }}
        />
        {generateHeaderText()}
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <Button
        icon="pi pi-map-marker"
        onClick={openNew}
        label="suivi de localisation"
      />

      {/* MODAL */}
      <Dialog
        draggable={false}
        visible={dialogVisibility}
        maximizable
        header={header}
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        {!isLoaded ? (
          <div className="card">
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          </div>
        ) : (
          <>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoom}
                onLoad={onMapLoad}
                onUnmount={onUnmount}
                options={{
                  styles: mapStyles,
                  disableDefaultUI: false,
                }}
              >
                {selectedDeliveryMan && polylineDeliveryMan && (
                  <>
                    {renderMarkersCustomers()}
                    <Polyline
                      path={polylineDeliveryMan}
                      options={{
                        strokeColor: "#000",
                        strokeOpacity: 1.0,
                        strokeWeight: 3,
                        // geodesic: true
                      }}
                    />
                  </>
                )}

                {deliveryMenPositions.map((deliveryManPosition) => {
                  if (
                    deliveryManPosition.location.latitude ||
                    deliveryManPosition.lastPosition.latitude
                  )
                    return (
                      <>
                        <Marker
                          key={deliveryManPosition._id}
                          options={{
                            icon: {
                              path: window.google.maps.SymbolPath
                                .FORWARD_CLOSED_ARROW,
                              rotation:
                                deliveryManPosition.location.angle ||
                                deliveryManPosition.lastPosition.angle,
                              scale: 5, // Adjust size of the marker
                              fillColor: "#35abdd",
                              fillOpacity: 1,
                              strokeWeight: 1,
                              scaledSize: new window.google.maps.Size(30, 30),
                            },
                          }}
                          position={{
                            lat:
                              deliveryManPosition.location.latitude ||
                              deliveryManPosition.lastPosition.latitude,
                            lng:
                              deliveryManPosition.location.longitude ||
                              deliveryManPosition.lastPosition.longitude,
                          }}
                        />
                        <OverlayView
                          position={{
                            lat:
                              deliveryManPosition.location.latitude ||
                              deliveryManPosition.lastPosition.latitude,
                            lng:
                              deliveryManPosition.location.longitude ||
                              deliveryManPosition.lastPosition.longitude,
                          }}
                          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        >
                          <div
                            style={{
                              position: "absolute",
                              bottom: "30px", // Adjust the distance above the marker
                              left: "50%",
                              transform: "translateX(-50%)",
                              background: "white",
                              border: "1px solid #ccc",
                              padding: "5px",
                              borderRadius: "5px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                              boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
                            }}
                          >
                            {deliveryManPosition.fullName}
                          </div>
                        </OverlayView>
                      </>
                    );
                })}
              </GoogleMap>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

export default RealTimeTracking;
