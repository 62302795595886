
import Api from "../utils/Api";

export class DeliverySessionsService {

  async getAll(queryParams){
    let response = {}
    try {
      const res = await Api.get(`admin/deliverysessions?${queryParams}`)
      response.data = res.data
      return response
    } catch (error) {
      console.log("error getting delivery sessions", error);
      response.error = error
      return response
    }
  }

  async create (payload){
    let response = {}
    try {
      const res = await Api.post(`admin/deliverysessions`,payload)
      response.data = res.data
      return response
    } catch (error) {
      console.log("error creating delivery sessions", error);
      response.error = error
      return response
    }
  }

  async markAsComplete (deliverySessionId){
    let response = {}
    try {
      const res = await Api.put(`admin/deliverysessions/${deliverySessionId}/complete`)
      response.data = res.data
      return response
    } catch (error) {
      console.log("error udapting delivery sessions status", error);
      response.error = error
      return response
    }
  }

  async deleteDeliverySession (deliverySessionId){
    let response = {}
    try {
      const res = await Api.delete(`admin/deliverysessions/${deliverySessionId}`)
      response.data = res.data
      return response
    } catch (error) {
      console.log("error deleting delivery session", error);
      response.error = error
      return response
    }
  }
}