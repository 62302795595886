import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import NoData from "../components/NoData";
//services
import { DeliveryPeopleService } from "../service/DeliveryPeopleService";
import { DateTime } from "luxon";
import { DeliverySessionsService } from "../service/DeliverySessionService";
import DeliverySessionsDetails from "../components/deliverySessions/DeliverySessionsDetails";
import { Button } from "primereact/button";
import ConfirmationModal from "../components/ConfirmationModal";
import AddDeliverySession from "../components/deliverySessions/AddDeliverySession";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

const DeliverySessions = () => {
  const deliverySessionService = new DeliverySessionsService();
  const deliveryMenService = new DeliveryPeopleService();
  const [deliverySessions, setDeliverySessions] = useState({
    records: [],
    count_records: 0,
  });
  const [deliveryMen, setDeliveryMen] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDeliveryMen, setLoadingDeliveryMen] = useState(false);
  const dt = useRef(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 50,
    page: 1,
    filters: {
      deliveryMan: null,
      date: DateTime.now().toJSDate(),
      status: null,
    },
    sortfield: "createdAt",
    sortorder: -1,
  });
  const [dialogVisibility, setDialogVisibilty] = useState(false);
  const [refetchData, setRefetchData] = useState(false);
  const [modalConfirmVisibility, setModalConfirmVisibility] = useState(false);
  const [loadingModalConfim, setLoadingModalConfirm] = useState(false);
  const [modalConfimProps, setModalConfirmProps] = useState({
    text: null,
    onConfim: null,
    onCancel: null,
  });
  const statusOptions = [
    { label: "Tous", value: null },
    { label: "En cours", value: "pending" },
    { label: "Complété", value: "completed" },
  ];

  useEffect(() => {
    lazyLoadData();
  }, [lazyParams]);

  useEffect(() => {
    if (refetchData) {
      lazyLoadData();
    }
  }, [refetchData]);

  useEffect(() => {
    loadDeliveryMen();
  }, []);

  async function lazyLoadData() {
    setLoading(true);
    const response = await deliverySessionService.getAll(generateQueryParams());
    if (response.data) {
      setDeliverySessions(response.data);
    } else {
      console.log(response.error);
    }
    setLoading(false);
  }
  async function loadDeliveryMen() {
    setLoadingDeliveryMen(true);
    const queryParams = "active=true&limit=100";
    const response = await deliveryMenService.getAll(queryParams);
    if (response.data) {
      setDeliveryMen(response.data.records);
    } else {
      console.log(response.error);
    }
    setLoadingDeliveryMen(false);
  }

  const generateQueryParams = () => {
    let queryParams = `sortfield=${lazyParams.sortfield}&sortorder=${lazyParams.sortorder}&current_page=${lazyParams.page}&total_skip=${lazyParams.first}&limit=${lazyParams.rows}`;

    if (deliverySessions.count_records) {
      queryParams += `&count_records=${deliverySessions.count_records}`;
    }
    if (lazyParams.filters.deliveryMan) {
      queryParams += `&deliveryMan=${lazyParams.filters.deliveryMan}`;
    }
    if (lazyParams.filters.date) {
      const date = DateTime.fromJSDate(lazyParams.filters.date).toFormat(
        "yyyy-MM-dd"
      );
      queryParams += `&date=${date}`;
    }
    if (lazyParams.filters.status) {
      queryParams += `&status=${lazyParams.filters.status}`;
    }

    return queryParams;
  };

  const onPage = (event) => {
    console.log(event);
    setLazyParams({
      ...lazyParams,
      first: event.first,
      rows: event.rows,
      page: event.page + 1,
    });
  };

  const onSort = (event) => {
    let sortorder;
    if (
      lazyParams.sortfield == null ||
      lazyParams.sortfield != event.sortField
    ) {
      sortorder = -1;
    } else {
      sortorder = lazyParams.sortorder * -1;
    }
    setLazyParams({
      ...lazyParams,
      first: 0,
      rows: 50,
      page: 1,
      sortfield: event.sortField,
      sortorder: sortorder,
    });
  };

  const statusFilter = () => {
    return (
      <Dropdown
        value={lazyParams.filters.status}
        placeholder="Statut"
        options={statusOptions}
        onChange={onStatusChanged}
        className="p-column-filter p-inputtext-sm"
      />
    );
  };

  const deliveryManFilter = () => {
    return (
      <Dropdown
        filter
        showClear
        showFilterClear
        value={lazyParams.filters.deliveryMan}
        placeholder="Livreurs"
        options={deliveryMen}
        onChange={onDeliveryManChanged}
        optionLabel="fullName"
        optionValue="_id"
        className="p-column-filter p-inputtext-sm mr-2"
      />
    );
  };

  const dateFilter = () => {
    return (
      <Calendar
        className="p-inputtext-sm mr-2"
        local={"fr"}
        style={{ minWidth: "100px" }}
        showButtonBar
        onChange={onDateChanged}
        value={lazyParams.filters.date}
        placeholder="date"
        id="basic"
      />
    );
  };

  const bodyTemplateStatus = (val) => {
    let severity, text;
    if (val.status === "pending") {
      severity = "#FAAE42";
      text = "En attente";
    } else if (val.status === "completed") {
      severity = "#05B171";
      text = "Complétée";
    }
    return (
      <div
        style={{
          backgroundColor: severity,
          borderRadius: 12,
          display: "inline-block",
        }}
        className="pr-2 pl-2"
      >
        <p className="text-sm font-medium lowercase" style={{ color: "#fff" }}>
          {text}
        </p>
      </div>
    );
  };

  const actionsBodyTemplate = (rowData) => {
    return (
      <div className="actions flex">
      <DeliverySessionsDetails
        rowData={rowData.expeditions}
        refetchData={refetchData}
        setRefetchData={setRefetchData}
      />
      {rowData.status !== "completed" &&
      <>
        <Button
          className="p-button-sm p-button-rounded p-button-text"
          icon="pi pi-check"
          style={{
            color: "green",
          }}
          tooltip="marquer comme complétée"
          tooltipOptions={{ position: "top" }}
          onClick={() => onMarkAsComplete(rowData._id)}
        />
        <Button
          className="p-button-sm p-button-rounded p-button-text p-button-danger"
          icon="pi pi-trash"
          tooltip="supprimer"
          tooltipOptions={{ position: "top" }}
          onClick={() => onDeleteDeliverySessionClick(rowData._id)}
        />
      </>
      }
    </div>
    );
  };

  const onDeleteDeliverySessionClick = (deliverysSessionId) => {
    confirmDialog({
      message: "Êtes-vous sûr de vouloir effectuer cette action ? Cette opération est irréversible.",
      header: "confirmation",
      acceptLabel: "Oui",
      rejectLabel: "Non",
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        const response = await deliverySessionService.deleteDeliverySession(deliverysSessionId)
        if(response.data){
          setLazyParams({
            first: 0,
            rows: 50,
            page: 1,
            filters: {
              deliveryMan: null,
              date: DateTime.now().toJSDate(),
              status: null,
            },
            sortfield: "createdAt",
            sortorder: -1,
          })
        } else {
          console.log("error deleting delivery session") 
          console.log(response.error) 
        }
      }
    })
  }
  
  const onStatusChanged = (event) => {
    setLazyParams({
      ...lazyParams,
      filters: {
        ...lazyParams.filters,
        status: event.value,
      },
    });
  };

  const onDeliveryManChanged = (event) => {
    setLazyParams({
      ...lazyParams,
      firs: 0,
      page: 1,
      page: 1,
      filters: {
        ...lazyParams.filters,
        deliveryMan: event.value,
      },
    });
  };

  const onDateChanged = (event) => {
    setLazyParams({
      ...lazyParams,
      firs: 0,
      page: 1,
      page: 1,
      filters: {
        ...lazyParams.filters,
        date: event.target.value,
      },
    });
  };

  const onMarkAsComplete = (deliverySessionId) => {
    setModalConfirmVisibility(true);
    setModalConfirmProps({
      text: "Êtes-vous sûr de vouloir effectuer cette action ? Cette opération est irréversible.",
      onConfim: async () => {
        setLoadingModalConfirm(true);
        await deliverySessionService.markAsComplete(deliverySessionId);
        setLoadingModalConfirm(false);
        setModalConfirmVisibility(false);
        setDialogVisibilty(false);
        lazyLoadData();
      },
      onCancel: () => {
        setModalConfirmVisibility(false);
      },
    });
  };

  const paginatorLeft = (
    <Button onClick={lazyLoadData} type="button" icon="pi pi-refresh" text />
  );

  return (
    <div className="grid crud-demo">
      <ConfirmDialog />
      <div className="col-12">
        <div className="mb-4 flex alignItems-center justify-content-between">
          <p className="text-2xl font-semibold">Séances de livraisons</p>
          <AddDeliverySession setRefetchData={setRefetchData}/>
        </div>

        <div className="card">
          <div className="overflow-x-auto mb-4 flex align-items-center justify-content-start">
            {deliveryManFilter()}
            {dateFilter()}
            {statusFilter()}
          </div>
          {modalConfirmVisibility && (
            <ConfirmationModal
              visible={modalConfirmVisibility}
              setVisible={setModalConfirmVisibility}
              onConfim={modalConfimProps.onConfim}
              onCancel={modalConfimProps.onCancel}
              text={modalConfimProps.text}
              loading={loadingModalConfim}
            />
          )}
          <DataTable
            paginator
            paginatorLeft={paginatorLeft}
            lazy
            first={lazyParams.first}
            loading={loading}
            rows={50}
            totalRecords={deliverySessions.count_records}
            onPage={onPage}
            filterDisplay="row"
            stripedRows
            ref={dt}
            rowHover
            responsiveLayout="scroll"
            value={deliverySessions.records}
            size="small"
            dataKey="_id"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} à {last} de {totalRecords}"
            emptyMessage={<NoData label="Aucune séances disponible" />}
          >
            <Column
              field="deliveryMan"
              header="Nom livreur"
              body={(rowData) => rowData.deliveryMan.fullName}
            />
            <Column
              field="estimatedCompletionTime"
              header="Temps estimé de fin"
              body={(rowData) =>
                `${rowData.estimatedCompletionTime.toFixed(2)} minutes`
              }
            />
            <Column
              field="totalDistance"
              header="Distance totale"
              body={(rowData) => `${rowData.totalDistance.toFixed(2)} km`}
            />
            <Column field="status" header="Statut" body={bodyTemplateStatus} />
            <Column
              field="startDate"
              header="Date début"
              body={(rowData) =>
                DateTime.fromISO(rowData.createdAt)
                  .setZone("Africa/Casablanca")
                  .toFormat("dd-MM-yyyy HH:mm")
              }
            />
            <Column
              field="completedDate"
              header="Date fin"
              body={(rowData) => {
                return (
                  <p>
                    {rowData.completedDate
                      ? DateTime.fromISO(rowData.completedDate)
                          .setZone("Africa/Casablanca")
                          .toFormat("dd-MM-yyyy HH:mm")
                      : ""}
                  </p>
                );
              }}
            />
            <Column header="actions" body={actionsBodyTemplate} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default DeliverySessions;
