import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import NoData from "../NoData";
import { ExpeditionsService } from "../../service/ExpeditionsService";
import OrdersByCustomers from "./OrdersByCustomers";
import { Button } from "primereact/button";
const DeliverySessionsDetails = ({
  rowData,
  refetchData,
  setRefetchData,
}) => {
  const expeditionService = new ExpeditionsService();
  const [dialogVisibility, setDialogVisibilty] = useState(false)
  const [loading, setLoading] = useState(false);
  const [ordersByCustomers, setOrdersByCustomers] = useState(null);

  useEffect(() => {
    if (dialogVisibility || refetchData) {
      async function getOrdersByCustomers() {
        console.log("get orders by customers");
        setLoading(false);
        const selectedExpeditions = rowData.join(",");
        const response = await expeditionService.getGrouppedByCustomers(
          selectedExpeditions
        );
        if (response && response.data) {
          setOrdersByCustomers(response.data);
        }
        setLoading(false);
      }
      getOrdersByCustomers();
    }
    return () => {
      if (refetchData) {
        setRefetchData(false);
      }
    };
  }, [dialogVisibility, refetchData]);



  const dt = useRef(null);

  const bodyTemplateStatus = (data) => {
    let severity, text;
    let isPending = false,
      isCompleted = false,
      isPartial = true;
    isPending = data.orders.every((order) => {
      return order.status === "pending" || order.status === "started";
    });
    if (!isPending) {
      isCompleted = data.orders.every((order) => {
        return (
          order.status === "delivered" ||
          order.status === "customer_not_found" ||
          order.status === "canceled"
        );
      });
    }
    if (isCompleted || isPending) {
      isPartial = false;
    }
    console.log(isPending);
    if (isPending) {
      severity = "#FAAE42";
      text = "En attente";
    } else if (isCompleted) {
      severity = "#05B171";
      text = "complétée";
    } else if (isPartial) {
      severity = "#007BFF";
      text = "partielle";
    }
    return (
      <div
        style={{
          backgroundColor: severity,
          borderRadius: 12,
          display: "inline-block",
        }}
        className="pr-2 pl-2"
      >
        <p className="text-sm font-medium lowercase" style={{ color: "#fff" }}>
          {text}
        </p>
      </div>
    );
  };

  const actionsBodyTemplate = (rowData) => {
    return (
      <div className="actions flex">
       <OrdersByCustomers
        rowData={rowData.orders}
        setRefetchData={setRefetchData}
      />
      </div>
    )
  }

  return (
    <>
    <Button 
      icon="pi pi-eye"
      className="w-auto p-button-secondary p-button-sm p-button-rounded p-button-text p-button-outlined"
      onClick={() => setDialogVisibilty(true)}
    />
    <Dialog
      draggable={false}
      visible={dialogVisibility}
      breakpoints={{ "1900px": "60vw", "640px": "100vw" }}
      header="Détails de la session"
      modal
      className="p-fluid"
      onHide={() => setDialogVisibilty(false)}
    >
      
      <DataTable
        loading={loading}
        rows={50}
        filterDisplay="row"
        stripedRows
        ref={dt}
        rowHover
        responsiveLayout="scroll"
        value={ordersByCustomers}
        size="small"
        dataKey="_id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="{first} à {last} de {totalRecords}"
        emptyMessage={<NoData label="Aucun commande disponible" />}
      >
        <Column field="customerName" header="nom client" />
        <Column
          field="total"
          header="Total"
          body={(rowData) => `${rowData.total} DH`}
        />

        <Column
          header="total commandes"
          body={(rowData) => rowData.orders.length}
        />
        <Column header="Statut livraison" body={bodyTemplateStatus} />
        <Column
          header="Actions"
          body={actionsBodyTemplate}
        />
      </DataTable>
    </Dialog>
    </>
  );
};

export default DeliverySessionsDetails;
